export default class Constants {
  static WEBSITE_URL = "https://www.radrun.club/";
  static BASE_URL = "https://api.radrun.club/api/";

  static HOST = "https://api.radrun.club/";

  static END_POINT = {
    UPLOAD_PROFILE_IMAGE: "user/upload-profile-image",

    REGISTER: "auth/sign-up",
    REQUEST_OTP: "auth/verify-otp",
    LOGIN: "auth/sign-in",
    FORGOT_PASSWORD: "auth/forgot-password",
    MY_PROFILE: "user/profile",
    RESET_PASSWORD: "auth/reset-password",

    ADD_CLUB: "auth/create-club",
    UPDATE_CLUB: "user/update-club/",
    GET_CLUBS_AND_SCHOOLS: "user/clubs-and-schools",
    GET_CLUB: "user/club",
    GET_CLUBS: "user/clubs",
    GET_SCHOOLS: "user/schools",
    GET_SCHOOL_CLASSES: "user/classes/",
    GET_SCHOOL_HOUSES: "user/houses/",
    GET_CLUB_TEAMS: "user/teams/",

    ADD_CLUB_ENTITY: "user/create-entity",
    UPDATE_CLUB_ENTITY: "user/update-entity",
    GET_CLUB_ENTITIES: "user/entities/",
    GET_CLUB_ENTITY: "user/entity/",

    // CHILDREN
    UNIQUE_ID: "get-new-indetifier",
    ADD_CHILD: "user/create-child",
    GET_CHILDREN: "user/get-children",
    GET_CHILD_BY_ID: "user/get-child/",
    UPDATE_CHILD_PROFILE: "user/update-child/",

    // Events
    ADD_PRIVATE_EVENTS: "events/requests",
    PRIVATEEVENTS: "events",
    PUBLICEVENT: "events/requests/public-event",
    FETCHALLEVENTBYID: "events/all/",
    MARK_EVENT_AS_COMPLETE: "events/mark-as-complete/",
    NEAREST_EVENTS: "events/get-nearest",
    ALL_EVENTS: "events/get-all",

    //Rad Run
    RAD_RUN: "rad-run",
    ALL_STATS: "rad-run/all-stats",
    CHILD_STATS: "rad-run/all-stats/"
  };
}
